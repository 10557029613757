import Api from "./api";
import { AxiosError, AxiosResponse } from "axios";

export class CouponApi extends Api {
  private token = "";

  constructor() {
    super();
    this.token = localStorage.getItem("token") || "";
  }

  public list(data: Record<string, any>): Promise<AxiosResponse> {
    return this.get<ServerResponse>("/coupon", {
      headers: {
        Authorization: "Bearer " + this.token
      },
      params: data
    });
  }

  public check(code: string): Promise<AxiosResponse> {
    return this.get<ServerResponse>(`/coupon/${code}`, {
      headers: {
        Authorization: "Bearer " + this.token
      }
    });
  }

  public create(data: Record<string, any>): Promise<AxiosResponse> {
    return this.post<ServerResponse>("/coupon", data, {
      headers: {
        Authorization: "Bearer " + this.token
      }
    });
  }

  public deleteCoupon(id:string): Promise<AxiosResponse> {
    return this.delete<ServerResponse>(`/coupon/${id}`, {
      headers: {
        Authorization: "Bearer " + this.token
      }
    });
  }
}
