




































































































































































































































































import { Component } from "vue-property-decorator";
import Vue from "vue";
import { AxiosResponse } from "axios";
import { UserApi } from "@/networks/UserApi";
import { CouponApi } from "@/networks/CouponApi";
import store from "@/store";

@Component({})
export default class PayTransaction extends Vue {
  private data: Record<string, any> = {};
  private error: Record<string, any> = {};
  private loading = false;
  private coupon = "";
  use_coupon = false;

  mounted() {
    this.getPayInfo();
    document.title = (this.$route.meta as any).title || "GO2TR BMS";
  }

  public async getPayInfo(): Promise<void> {
    try {
      const res: AxiosResponse = await new UserApi().getPayInfo(
        this.$route.params.transaction,
        this.$route.params.user
      );
      this.data = res.data.data;
    } catch (error: any) {
      this.error = error.response.data;
    }
  }

  public async submitAndGetUrl(): Promise<void> {
    this.loading = true;
    try {
      const res: AxiosResponse = await new UserApi().submitAndGetUrl(
        this.$route.params.transaction,
        this.$route.params.user,
        this.use_coupon ? this.coupon : ""
      );
      window.location.href = res.data.data.url.action;
    } catch (error: any) {
      store.commit("showError", {
        message: error.response.data.message || "خطای نامشخص",
        color: "danger"
      });
    } finally {
      this.loading = false;
    }
  }

  async checkCoupon() {
    try {
      const res: AxiosResponse = await new CouponApi().check(this.coupon);
      if (res) {
        this.use_coupon = true;
      }
      store.commit("showError", {
        message: res.data.message || "کد تخفیف اعمال شد",
        color: "success"
      });
    } catch (error: any) {
      store.commit("showError", {
        message: error.response.data.message || "خطای نامشخص",
        color: "error"
      });
    }
  }
}
